import * as React from "react";
import { useNavigate } from "react-router-dom";

import './card.scss'
import { Box } from "@mui/material";

export default function Card({
  img,
  title,
  btnColour,
  btnText,
  navUrl,
}: {
  img: any;
  title: string;
  btnColour: string;
  btnText: string;
  navUrl: string;
}) {
  const navigate = useNavigate();

  return (
    <Box className="card">
      <Box className="card__body" sx={{display: 'flex' ,flexDirection: 'column', alignItems: 'center' ,pt: 1}}>
        <img src={img} className="card__image" />
        <h2 className="card__title">{title}</h2>
      </Box>
      <button
        onClick={() => navigate(navUrl)}
        style={{ backgroundColor: btnColour }}
        className="card__btn"
      >
        {btnText}
      </button>
    </Box>
  );
}
