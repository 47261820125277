import * as React from "react";
import { Button, CircularProgress, MenuItem, Select, TextField } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DoneIcon from '@mui/icons-material/Done';
import Close from "@mui/icons-material/Close";
import {
  DataGrid,
} from "@mui/x-data-grid";
import SideNavigation from "../../navigation/SideNavigation";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ToastContainer, toast } from 'react-toastify';
import "./subCategory.scss";
import { CategoryAPI, IngredientAPI, ProductAPI, SubCategoryAPI } from "../../api/services";
import AddSubCategoryModal from "./addSubCategory";
import EditModal from "./editSubCategory";
import { subCategory } from "../../types/subCategory";
import DeleteIcon from '@mui/icons-material/Delete';



export default function SubCategoryScreen() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(false)
  const [isAddModalOpen, setAddModalOpen] = React.useState(false);
  const [isEditModalOpen, setEditModalOpen] = React.useState(false);
  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [isOpenActivate, setIsOpenActivate] = React.useState(false);
  const [specialKey, setSpecialKey] = React.useState('')
  const [isOpenRemove, setIsOpenRemove] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<any>(0)
  const [selectedSubCategory, setSelectedSubCategory] = React.useState<any>({})
  const [selectedSubCategoryProducts, setSelectedSubCategoryProducts] = React.useState<any>([])
  const [isDeleted, setIsDeleted] = React.useState({
    id: 0,
    is_deleted: false
  });
  const [categories, setCategories] = React.useState([])
  const [isEditRow, setIsEditRow] = React.useState<subCategory>({
    id: 0,
    name: '',
    contains: '',
    category:'',
    category_id: 0,
    is_deleted: false,
    created_by: 0,
    updated_by: 0,
    created_date: new Date,
    modified_date:new  Date,
  })

  const [SubCategoryData, setSubCategoryData] = React.useState<subCategory[]>([])


  const openAddModal = () => {
    setAddModalOpen(true);
  };

  const closeAddModal = () => {
    setAddModalOpen(false);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
  };

  React.useEffect(() => {
    if (!localStorage.getItem('formData')) {
      navigate('/Login')
    } else {
      setIsLogged(true)
      SubCategoryAPI.getAllSubCategory().then((res) => {
        setSubCategoryData(res.data);
        console.log(res.data)
        setIsLoading(false)
      })
      CategoryAPI.getAllCategory().then((res) => {
        setCategories(res.data);
      })
    }


  }, [isAddModalOpen, isOpenDelete, isEditModalOpen, isOpenActivate])

  const getProductsForCategory = () => {
    if(Object.keys(selectedSubCategory).length > 1){
      ProductAPI.get_SubCategory_Products(selectedSubCategory.id).then((res) => {
        setSelectedSubCategoryProducts(res.data);
      })
    }
    
  }

  React.useEffect(() => {
    getProductsForCategory();
  },[selectedSubCategory])

  const removeCategory = () => {
    if(specialKey === 'All3Gr0w'){
      SubCategoryAPI.deleteSubCategory(selectedSubCategory.id).then((res) => {
        toast.success("Product SubCategory Deleted", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
      });
      setIsOpenRemove(false);
        SubCategoryAPI.getAllSubCategory().then((res) => {
          setSubCategoryData(res.data);
        })
      })
    }else{
      toast.error("Incorrect Key!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    }
  }

  const deleteSubCategory = (isDeleted: any) => {
    const deletdId = isDeleted.id;
    const data = { is_deleted: isDeleted.is_deleted }
    console.warn(deletdId, data)
    SubCategoryAPI.updateSubCategory(data, deletdId).then((res) => {
      console.log(res)
      setIsOpenDelete(false)
      setIsOpenActivate(false)
    })
  }

  const columns: any = [
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params: any) => {
        const onViewClick = (e: any) => {
          setIsEditRow(params.row);
          setEditModalOpen(true);
        };

        return (
          <>
            <Button
              variant="contained"
              sx={{ m: 0, color: "#fff", backgroundColor: "#70a132", width: 30 }}
              onClick={onViewClick}
            >
              <ModeEditIcon style={{ fontSize: 23 }} />
            </Button>

          </>
        );
      },
    },
    { field: "name", headerName: "Name", width: 300 },
    { field: "category", headerName: "Category", width: 300 },
    {
      field: "is_delete", headerName: "Is Active", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.is_deleted ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: false });
                      if(!params.row.is_deleted){
                        setIsOpenDelete(true);
                      }else{
                        setIsOpenActivate(true);
                      }
                      
                    }}
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30 }}
                    onClick={() => {
                      setIsDeleted({ id: params.row.id, is_deleted: true });
                      if(!params.row.is_deleted){
                        setIsOpenDelete(true);
                      }else{
                        setIsOpenActivate(true);
                      }
                    }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },
    {
      field: "", headerName: "Delete", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              variant="contained"
              sx={{
                m: 1,
                color: "#fff",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", // Set the background color on hover
                },
              }}
              onClick={() => {
                setSelectedSubCategory(params.row)
                setIsOpenRemove(true)
                setSelectedSubCategoryProducts([])
              }}
            >
              <DeleteIcon style={{ fontSize: 23 }} />
            </Button>
          </>
        );
      }
    },
    {
      field: "icon", headerName: "Has Icon", width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params: any) => {
        return (
          <>
            {
              params.row.icon === null ?
                (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "red", width: 30 }}
                    
                  >
                    <Close style={{ fontSize: 23 }} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ m: 1, color: "#fff", backgroundColor: "#70a132", width: 30,"&:hover": {
                  backgroundColor: "#70a132 !important", // Set the background color on hover
                }, }}
                  >
                    <DoneIcon style={{ fontSize: 23 }} />
                  </Button>
                )}
          </>);
      }
    },
  ];
  return (
    <>
      {isLogged ? (<div id="home-container">
        <Helmet>
          <title>{'AlleGrow-SubCategory'}</title>
        </Helmet>
        <div>
          <SideNavigation />
        </div>
        <div className="child">
          <div className="header">
            <p style={{ fontSize: 25, fontWeight: 500, color: "#70a132", marginTop: 80  }}>SubCategories</p></div>
          <div className="tableheader">

            <Button
              variant="contained"
              sx={{ color: "#70a132", backgroundColor: "#fff", width: 25, fontSize: 15, height: 30 }}
              onClick={openAddModal}
            >Add
            </Button>
          </div>
          {isLoading ?
            (
              <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                <CircularProgress size={50} style={{ marginTop: 50, color: '#70a132' }} />
              </div>
            ) : (<div style={{ width: "100%" }}>
              <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value as number)}
                    displayEmpty={true}
                    inputProps={{ 'aria-label': 'Select an option' }}
                    className='drop-down'
                >
                    <MenuItem value={0}>Select a category</MenuItem>
                    {categories.map((option: any) => (
                        <MenuItem key={option.name} value={option.id}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
              <DataGrid
                style={{ maxHeight: "100%", paddingLeft: 15, paddingRight: 15 }}
                rows={selectedCategory !== 0 ? SubCategoryData.filter((item) => item.category_id === selectedCategory) : SubCategoryData}
                columns={columns}
                getRowId={row => row.id}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                rowSelection={false}
              />
            </div>)}
        </div>
        <Dialog
          open={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deactivate Food SubCategory"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to deactivate this food subCategory?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteSubCategory(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenDelete(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenActivate}
          onClose={() => setIsOpenActivate(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Activate Food SubCategory"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to activate this food subCategory?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => deleteSubCategory(isDeleted)}>Yes</Button>
            <Button onClick={() => setIsOpenActivate(false)} autoFocus>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={isOpenRemove}
          onClose={() => setIsOpenRemove(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete Product SubCategory"}
          </DialogTitle>
          <DialogContent>
            { selectedSubCategoryProducts.length < 1 && (
              <>
            <DialogContentText id="alert-dialog-description">
              Please enter the special key to remove the product subcategory:
            </DialogContentText>
            <TextField
                    variant="standard"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    name="name"
                    InputProps={{
                        disableUnderline: true, // <== added this
                    }}
                    placeholder="Key"
                    className='addText'
                    onChange={(e: any) => setSpecialKey(e.currentTarget.value)}
                />
                </>
                  )}
                <DialogContentText id="alert-dialog-description" style={{fontWeight: 'bold'}}>
              The following products belong to this subcategory:
            </DialogContentText>
            {selectedSubCategoryProducts.length < 1 ? 
            (<>
                <DialogContentText id="alert-dialog-description">
              No Products Found
            </DialogContentText>
            </>) : 
            (
              <>
                {selectedSubCategoryProducts.map((x: any, idx: any) => {
              return(
                <DialogContentText id="alert-dialog-description">
                  <ul>
                    <li>
                    {x.name}
                    </li>
                  </ul>
              
            </DialogContentText>
              )
            })
          }
              </>
            )
            
            }
          </DialogContent>
          <DialogActions>
            
            <Button onClick={() => setIsOpenRemove(false)} autoFocus>
              Cancel
            </Button>
            {selectedSubCategoryProducts.length < 1 && (
              <Button variant="contained" sx={{m: 1,
                color: "#fff !important",
                backgroundColor: "red",
                width: 30,
                "&:hover": {
                  backgroundColor: "red !important", color: '#fff !important'// Set the background color on hover
                },}} onClick={removeCategory}><DeleteIcon/></Button>
            )}
            
          </DialogActions>
        </Dialog>
        <AddSubCategoryModal open={isAddModalOpen} onClose={closeAddModal} />
        <EditModal open={isEditModalOpen} onClose={() => {
          closeEditModal()
          setIsLoading(true);
          SubCategoryAPI.getAllSubCategory().then((res) => {
            setSubCategoryData(res.data);
            setIsLoading(false)
          })
        }} SubCategoryRow={isEditRow} />
      </div>) : (
        <CircularProgress />
      )}
      <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
    </>
  );
}