import React, { Fragment } from "react";
import "./landingPage.scss";
import Navbar from "./navbar";
import Footer from "./footer";
import Home from "./home";
import { Box } from "@mui/material";

interface Link {
  url: string;
  title: string;
}

const landingPage = () => {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
  ];

  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Home />
      <Footer />
    </>
  );
};

export default landingPage;
