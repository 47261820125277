import React, { Fragment } from "react";
import image from "../../assets/images/Idonette Picture_Website_New.png";
import "./services.scss";
import Navbar from "./navbar";
import Footer from "./footer";
import { Box } from "@mui/material";

interface Link {
  url: string;
  title: string;
}
const Services = () => {
  const diaryLink =
    "https://www.mygc.co.za/external/diary/08f5f56e-9e95-4840-94d2-2f513c1bf0e8";
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
  ];
  const handleClick = () => {
    window.open(diaryLink);
  };

  return (
    <>
    <Navbar navbarLinks={lnks} />
    <Box sx={{pt: '0px', height: 'calc(100vh - 150px)', marginBottom: 10, flex: 1}} className='servicesContainer'>
    <Box
      
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          pt: 5
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h2 style={{ fontFamily: "margarosa", marginBottom: 0, fontSize: '2em' }}>Services</h2>
        </Box>
      </Box>
      <Box
      className='top'
      sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          pt: 5
        }}>
          <Box className='text-box' sx={{width: '50%', pl: 5, pr: 5}}>
          <p style={{textAlign: 'justify', fontSize: '20px'}}>
              Idonette has a special interest in food allergies & -intolerances.
              She understands the challenges faced by affected children and
              families, and therefore strives to offer support in improving
              quality of life. She is registered with the HPCSA (The Health
              Professions Council of South Africa), ALLSA (The Allergy Society
              of South Africa), and AFSA (Allergy Foundation South Africa). She
              is the current chairperson of the ALLSA Allergy Dietetic Interest
              Group (ADIG) and presents on food allergy related topics at
              congresses and workshops on a regular basis. In 2018, she
              completed her master’s degree (cum-laude) at the North West
              University (NWU).<br></br> <br></br>
              Her passion for food allergies and -intolerances originated with
              the diagnosis of multiple food allergies in her first-born son in
              2011. Her interest and experience in this field grew during the
              management of food protein-induced enterocolitis syndrome (FPIES)
              in her 2nd born son. Her last-born daughter was also diagnosed
              with multiple food allergies, and selected food additive
              intolerances. Food additives causes adverse reactions in her
              children, including behaviour changes, and so her interest
              continues to grow in this field.  <br></br>
              <br></br>
              Having faced challenges as a family, she has learned to carefully
              assess and identify culprit foods & ingredients. By excluding
              those triggers, they now enjoy a much better quality of life as a
              result. It’s because those very challenges, that she was
              determined to develop a tool to bring some relief to others on the
              same journey. She is the founder and Managing Director of
              Allegrow, which will continue to expand.
            </p>
            <button className='appointmentBtn' onClick={handleClick}>
               <p>Book an online appointment!</p>
             </button>
          </Box>
          <Box sx={{width: '50%', display: 'flex', justifyContent: 'center'}}>
            <img src={image} alt="Image" style={{
                width: 'auto',
                aspectRatio: 1,
                height: '100%',
                maxWidth: 500,
                maxHeight: 700
            }}/>
          </Box>
      </Box>
    </Box>
    <Footer />
    </>
    
  );
};

export default Services;
