import React, { Fragment } from "react";
import "./about.scss";
import logo from "../../assets/logo/FoodAllergySupportApp.png";
import Navbar from "./navbar";
import Footer from "./footer";
import { ProductAPI } from "../../api/services";
import { Box } from "@mui/material";
interface Link {
  url: string;
  title: string;
}

const About = () => {
  const lnks: Link[] = [
    { url: "/", title: "Home" },
    { url: "/About", title: "About" },
    { url: "/Services", title: "Services" },
    { url: "/", title: "Allergy Information" },
    { url: "/partners", title: "Partners" },
    { url: "/form", title: "Contact" },
  ];

  const [totalProducts, setTotalProducts] = React.useState(0);

  React.useEffect(() => {
    ProductAPI.getProductCount().then((res) => {
      setTotalProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar navbarLinks={lnks} />
      <Box
        sx={{
          pt: "0px",
          height: "calc(100vh - 150px)",
          marginBottom: 10,
          flex: 1,
        }}
        className="aboutContainer"
      >
        <Box
          className="top"
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            pt: 5,
          }}
        >
          <Box
            className="top-left"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h2
              style={{
                fontFamily: "margarosa",
                marginBottom: 0,
                fontSize: "2em",
              }}
            >
              About Us
            </h2>
          </Box>
          <Box
            className="top-right"
            sx={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img className="homeImage" src={logo} alt="Image" />
          </Box>
        </Box>
        <Box
          sx={{
            textAlign: "justify",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ width: "80%", fontSize: "20px" }}>
            The Allegrow Food Allergy Support Application (App) was developed by
            a Registered Dietitian, who understands the challenges of living
            with food allergies & -intolerances. She identified an opportunity
            to offer support by creating an app which assists with suitable food
            product selection, in the comfort of your home or elsewhere.
          </p>

          <p style={{ width: "80%", fontSize: "20px" }}>
            Food label reading takes a tremendous amount of time. The Allegrow
            app strives to improve quality of life, by reducing time spent on
            in-store label-reading. The app assists food allergic & – intolerant
            individuals to discover a greater variety of suitable SA free-from
            food products. Explore multiple functionalities, including creating
            personalised user profiles & shopping lists, as well as finding a
            healthcare practitioner in your area. The dream realised, and the
            app was launched in November 2023.
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            {totalProducts > 0 && (
              <>
                <i>
                  Allegrow currently contains{" "}
                  <b style={{ color: "#70A132" }}>{totalProducts}</b> food
                  products, and will continue to expand!
                </i>
              </>
            )}
          </p>
          <p style={{ width: "80%", fontSize: "20px" }}>
            We hope that you will absolutely love using this app!
          </p>
        </Box>
        <Box
          className="about-paragraph-container"
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <button
            className="aboutBlock"
            style={{ marginBottom: "50px", marginTop: "50px" }}
          >
            {" "}
            Please visit and follow-up with a Healthcare Practitioner for
            accurate<br></br>
            diagnosis & management of your food allergies – and/or intolerances.
            <br></br>
            <br></br>
            This app does not replace your responsibility to read ingredients on
            food labels<br></br>
            before purchase and consumption.
          </button>
        </Box>
      </Box>

      <Footer />
    </>
    // <Fragment>
    //   <Navbar navbarLinks={lnks} />
    //   <div className="about-container">
    //     <div className="aboutHeader">
    //       <div className="about-left-column">
    //         <h1>About Us</h1>
    //       </div>
    //       <div className="about-right-column">
    //         <img className="aboutImage" src={logo} alt="About Us Image" />
    //       </div>
    //     </div>

    //     <div className="about-paragraph-container" style={{textAlign: 'justify'}}>
    //       <p>The Allegrow Food Allergy Support Application (App) was developed by
    //         a Registered Dietitian, who understands
    //         the challenges of living with food allergies & -intolerances. She
    //         identified an opportunity to offer support by creating
    //         an app which assists with suitable food product selection, in the
    //         comfort of your home or elsewhere.</p>

    //       <p>
    //       Food label reading takes a tremendous amount of time. The Allegrow
    //         app strives to improve quality of life, by reducing
    //         time spent on in-store label-reading. The app assists food allergic
    //         & – intolerant individuals to discover a greater variety
    //         of suitable SA free-from food products. Explore multiple
    //         functionalities, including creating personalised user profiles &
    //         shopping lists,
    //         as well as finding a healthcare practitioner in your area. The dream
    //         realised, and the app was launched in November 2023.
    //       </p>
    //       <p>
    //       {totalProducts > 0 && (
    //           <>
    //             <i>Allegrow currently contains <b style={{color: '#70A132'}}>{totalProducts}</b> food products, and will continue to expand!</i>
    //           </>
    //         )}
    //       </p>
    //       <p>
    //       We hope that you will absolutely love using this app!
    //       </p>

    //     </div>
    //     <div className="about-paragraph-container" style={{textAlign: 'justify'}}>
    //     <button className="aboutBlock" style={{marginBottom: '50px', marginTop: '50px'}}>
    //         {" "}
    //         Please visit and follow-up with a Healthcare Practitioner for
    //         accurate<br></br>
    //         diagnosis & management of your food allergies – and/or intolerances.
    //         <br></br>
    //         <br></br>
    //         This app does not replace your responsibility to read ingredients on
    //         food labels<br></br>
    //         before purchase and consumption.
    //       </button>
    //     </div>
    //   </div>
    //   <Footer />
    // </Fragment>
  );
};

export default About;
