import React, { Fragment } from 'react'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube, FaLock } from 'react-icons/fa';
import './footer.scss';
import { Box } from '@mui/material';

const Footer: React.FC = () => {
  return (
      <footer className="footer">
        <div className="footer__left">
          <a href="https://www.facebook.com/AllGrowSA">
            <FaFacebook className="footer__icon" />
          </a>
          <a href="https://www.instagram.com/allegrowsa/">
            <FaInstagram className="footer__icon" />
          </a>
          <a href="https://whatsapp.com/channel/0029VaZ5QaN2phHGZcmyOA2p">
            <FaWhatsapp className="footer__icon" />
          </a>
          <a href="https://www.youtube.com/@AlleGrowSA">
            <FaYoutube className="footer__icon" />
          </a>
        </div>
        <div className="footer__middle">Food Allergy Support App</div>
        <div className="footer__right">
          Powered by Drivatic
          <a href="https://allegrow.co.za/Login">
            <FaLock className="footer__lock-icon" />
          </a>
        </div>
      </footer>
  );
};
  
  export default Footer;
